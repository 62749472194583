// ==========================================================================
// BASE STYLES
// ==========================================================================

/* Base Styles -------------------- */
* {
  box-sizing: border-box;
}

/* Pseudo-classes ------------------ */
a {
  &:link {
    color: $color-link-default;
    text-decoration: none;
  }
  &:visited {
    color: $color-link-visited;
  }
  &:hover {
    color: darken($color-link-default,50%);
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}
