// ==========================================================================
// BUTTONS
// ==========================================================================

a.btn {
  display: inline-block;
  color: $color-text-light;
}

.btn {
  background: $grey;
  border-radius: 0;
  border: 0;
  color: $color-text-light;
  cursor: pointer;
  font-family: $font-stack-bold;
  line-height: 1.25;
  margin-bottom: 0.25em;
  padding: 9px 16px;
  width: 100%;
  text-align: center;
  font-size: 1.3333em;
  text-transform: uppercase;
  &.btn-inline {
    padding: 6px 12px;
    margin-bottom: 0;
  }
  &.btn-action {
    background: $red;
  }
  &.btn-thinking {
    animation: thinking-pulse 3s infinite;
  }
  &.btn-success {
    background: darken($green, 5%);
  }
  &[disabled] {
    opacity: 1 !important;
  }
}

.smoke-form-support {
  .btn {
    &.btn-action {
      background: $green;
    }
  }
}

@keyframes thinking-pulse {
  0% {background: $blue}
  50% {background: lighten($blue, 20%)}
  100% {background: $blue}
}

.message .btn {
  background: $white;
  color: $blue;
  margin-top: 0.5em;
  margin-bottom: 0;
}

.login-form .btn {
  margin-top: 0.25em;
}
