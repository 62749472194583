// ==========================================================================
// HEADER STYLING .BLOCK{__ELEMENT[--MODIFIER]}
// ==========================================================================

header {
  position: relative;
  background-color: $black;
  z-index: 20;
  .website-name {
   color: $red;
   padding: 0.25em 0 0.1em;;
   margin-bottom: 0;
   font-family: $font-stack-bold;
   font-weight: normal;
   text-transform: uppercase;
   font-size: 2.6em;
   text-align: center;
   width: 100%;
  }
}
