// ==========================================================================
// LISTS
// ==========================================================================

.message {
  padding: 1em;
  margin-bottom: 1em;
  color: darken($color-text-light, 3%);
  h3,
  p {
    margin: auto;
  }
  a {
    color: darken($color-text-light, 3%);
  }
}

.message-error {
  background-color: $red;
}

.message-success {
  background-color: $blue;
}
