// ==========================================================================
// SMOKE STATS
// ==========================================================================

.smoke-stats {
	text-align: center;

	p {
		margin-top: 0;
	}
	.smoke-info {
		margin-bottom: 0.5em;
	}
	.before-smoke-counter {
		margin-bottom: 0.5em;
	}
	.smoke-counter {
		font-size: 5rem;
		margin: 0;
		line-height: 1;
	}
}

.smoke-charts {
	h2 {
		margin: 0.7em 0.7em 0;
	}
	.radio-buttons {
		.form-label {
			padding-top: 2px;
		}
	}
}

.x-axis,
.y-axis {
	path {
		display: none;
	}
	line {
		stroke: #fff;
	}
	text {
		fill: #fff;
	}
}

.x-gridlines {
	path,
	line {
		stroke: rgba(255, 255, 255, 0.5);
	}
	path {
		display: none;
	}
}

.y-axis-box {
	path {
		display: block;
		stroke: rgba(255, 255, 255, 0.5);
	}
}

.d3-chart {
	text.label {
		fill: rgba(255, 255, 255, 0.5);
		font-size: 0.5em;
		text-anchor: middle;
	}
}
