// ==========================================================================
// TYPOGRAPHY
// ==========================================================================

/* Web Fonts -------------------- */
// @include font-face('Abolition Regular', abolition-regular);
@include font-face('Geomanist', geomanist-regular-webfont);
@include font-face('GeomanistItalic', geomanist-regular-italic-webfont);
@include font-face('GeomanistBold', geomanist-bold-webfont);

body {
	font-family: $font-stack-regular;
	line-height: 1.3;
}

em {
	font-family: $font-stack-italic;
}

strong {
	font-family: $font-stack-bold;
}

h1,
h2,
.smoke-counter {
	font-family: $font-stack-bold;
	font-weight: normal;
	text-transform: uppercase;
	//-webkit-font-smoothing: antialiased;
}

.main-content h1 {
	margin: 0 auto;
	padding: 50px 30px 30px;
	text-align: center;
	// max-width: 80%;
	font-size: 9vw;
	line-height: 1;
	@media #{$break-tablet} {
		font-size: 7vw;
	}
	@media #{$break-wide} {
		font-size: 4vw;
	}
}

h2 {
	font-size: 1.444em;
}

p.p-large {
	font-size: 1.2223em;
	.smaller {
		font-size: 0.81818em;
	}
}
