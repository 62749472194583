// ==========================================================================
// LISTS
// ==========================================================================

ul,
ol {
	padding-left: 0;
}

li {
	margin-bottom: 0.5em;
	&.no-icon {
		color: $red;
		list-style-type: none;
		font-family: $font-stack-bold;
		font-weight: normal;
	}
}

.smoke-list ul {
	padding-left: 30px;
	color: $grey-lighter;
	span.text-smoke {
		color: $red;
	}
	span.text-support {
		color: $green;
	}
	.item {
		position: relative;
		button {
			position: absolute;
			top: 1px;
			right: 0;
			webkit-appearance: none;
			background-color: transparent;
			border: 0;
			color: $grey;
		}
	}
}

.smoke-list {
	ul {
		li {
			&:not(.skip-number) {
				list-style: none;

				div:before {
					content: attr(data-smokenumber) '.';
					position: absolute;
					left: -25px;
				}
			}
			&.skip-number {
				list-style: none;
			}
		}
	}
}
