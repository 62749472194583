// ==========================================================================
// MIXINS
// ==========================================================================

/* Web fonts -------------------- */
@mixin font-face($family, $file) {
  @font-face {
    font-family: $family;
    src: url('#{$path-font}/#{$file}.eot');
    src: url('#{$path-font}/#{$file}.eot?#iefix') format('embedded-opentype'),
         url('#{$path-font}/#{$file}.woff') format('woff'),
         url('#{$path-font}/#{$file}.ttf') format('truetype');
  }
}

/* Text -------------------- */
@mixin text($size, $l-height: null, $weight: null, $color: null) {
  font-size: $size;
  line-height: $l-height;
  font-weight: $weight;
  color: $color;
}

/* Box Shadow -------------------- */
@mixin boxshadow($offsetX, $offsetY: $offsetX, $blurRadius: 0, $spreadRadius: 0, $color: 'transparent') {
  box-shadow: $offsetX $offsetY $blurRadius $spreadRadius $color;
}

/* Flexbox -------------------- */
/* Thanks to https://github.com/mastastealth/sass-flex-mixin */
@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

/* Background gradient -------------------- */
@mixin linear-gradient-b($args...) {
  background-image: -o-linear-gradient($args);
  background-image: -moz-linear-gradient($args);
  background-image: -webkit-linear-gradient($args);
  background-image: linear-gradient($args);
}
