// ==========================================================================
// ICONS
// ==========================================================================

// Arrow image icon
.arrow {
  width: 50px;
  margin-top: 150px;

  @media #{$break-tablet} {
    display: none;
  }
}
