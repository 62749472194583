// ==========================================================================
// FOOTER STYLING
// ==========================================================================

.main-nav {
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0;
  list-style-type: none;
  margin: 0;
  li {
    margin: 0;
    width: 100%;
    line-height: 1.5em;
  }
  a, button {
    font-size: 0.833333em;
    padding: 0.7em 0 0.5em;
    display: block;
    text-align: center;
    font-family: $font-stack-bold;
    text-transform: uppercase;
    line-height: 1.5em;
    &:hover {
      background-color: $white;
      text-decoration: none;
    }
    &.active {
      color: $white;
      background: none;
      text-decoration: none;
      cursor: default;
    }
  }
  button {
    background-color: transparent;
    border: 0;
    width: 100%;
    line-height: 1.5;
    color: $color-link-default;
    &:hover {
      color: darken($color-link-default,50%);
      cursor: pointer;
    }
  }
}
