// ==========================================================================
// LAYOUT STYLING .BLOCK{__ELEMENT[--MODIFIER]}
// ==========================================================================

.container {
	font-size: 1.125em;
	color: $color-text-light;
}

header,
footer,
.main-content {
	max-width: 380px;
	width: 100%;
	margin: 0 auto;
	//background-color: $black;
}

.main-content {
	position: relative;
	min-height: 400px;
	text-align: left;
	h1:first-of-type,
	h2:first-of-type {
		margin-top: 0;
	}
	.smoke-charts h2 {
		margin-top: 1em;
	}
}

.smoke-stats,
.smoke-form,
.smoke-list,
.login-form,
.view-loading {
	padding: 1em 0.75em;
	p:first-of-type {
		margin-top: 0;
	}
}

.smoke-list {
	padding-bottom: 1em;
}

.smoke-stats {
	background-color: $black;
}

.smoke-list p {
	margin: 0;
}

.smoke-list + .smoke-list {
	padding-top: 0;
}

.smoke-form {
	padding-bottom: 0.1em;
}

.border-bottom-grey {
	border-bottom: 1px solid $grey-light;
}

.view-loading {
	p {
		font-size: 2em;
		text-align: center;
		color: $red;
		font-weight: bold;
		margin-top: 0.5em;
	}
}

li.view-loading {
	width: 100%;
}

.grid-container {
	display: grid;
	grid-template-columns: 48% 48%;
}

.grid-container-third {
	grid-template-columns: calc(100% / 3) calc(100% / 3) calc(100% / 3);
}

.smoke-form-support {
	.grid-container-third {
		grid-template-columns: 32% 32% 32%;
	}
}

.grid-container-quart {
	grid-template-columns: 25% 25% 25% 25%;
}
