// ==========================================================================
// VARIABLES
// ==========================================================================

/* Descriptive color variables ------------------ */
$white          : #fff;
$black          : #242424;
$black-lighter  : #333;
$grey-light     : #eee;
$grey-lighter   : #bbb;
$grey           : #878787;
$red            : #bf1b06;
$blue           : #11638a;
$green          : #42a019;
//...

/* Functional color variables ------------------ */
$color-primary         : $white;
$color-secondary       : $white;

$color-text-light   : $white;
$color-text-base    : $grey;
$color-text-dark    : lighten($black, 20%);

$color-border-light : lighten($grey, 35%);
$color-border-dark  : $grey;

$color-link-default : $red;
$color-link-visited : $red;

$color-shadow       : rgba($black,.8);
$color-body         : $white;

/* Border radius ------------------ */
$br: 10px;

/* Asset paths ------------------ */
$path-img  : '../img';
$path-font : '/fonts';

/* Font stacks ------------------ */
$stack-sans-serif         : Helvetica, Arial, sans-serif;
$font-stack-regular       : 'Geomanist', $stack-sans-serif;
$font-stack-italic        : 'GeomanistItalic', $stack-sans-serif;
$font-stack-bold          : 'GeomanistBold', $stack-sans-serif;

/* Media query breakpoints ------------------ */
// To use: @media #{$break-narrow} {...}
$break-tablet : '(min-width: 768px)';
$break-wide   : '(min-width: 1024px)';
