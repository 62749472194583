// ==========================================================================
// FORMS
// ==========================================================================

form .grid-container {
	margin-top: 1em;
	grid-gap: 2%;
}

.form-row {
	margin-bottom: 0.5em;
}

.form-label,
legend {
	display: block;
	margin-right: 0.5em;
	width: 120px;
	font-family: $font-stack-bold;
	vertical-align: top;
	line-height: 2em;
}

.radio-buttons {
	.form-label {
		width: auto;
		font-family: $font-stack-regular;
		text-align: center;
		margin-right: 0;
		cursor: pointer;
		font-size: 0.833333em;
	}
	input[type='radio'] {
		display: none;
	}
	label.form-selected {
		background-color: $red;
	}
}

fieldset,
legend {
	padding: 0;
	margin: 0;
	border: 0;
}

fieldset .grid-container {
	margin-top: 0;
}

.form-checkbox {
	margin-bottom: 0.5em;
	&.form-selected-true {
		background-color: $red;
	}
	.form-label {
		display: block;
		margin-right: 0;
		width: auto;
		text-align: center;
		cursor: pointer;
	}
	input[type='checkbox'] {
		display: none;
	}
}

.smoke-form-support {
	label.form-selected,
	.form-checkbox.form-selected-true {
		background-color: $green;
	}
}

form input,
.recipe-filter input,
form textarea {
	font-family: $font-stack-regular;
}

.Select,
form input[type='text'],
form input[type='password'],
form input[type='number'],
form input[type='file'] {
	width: 100%;
	vertical-align: top;
}

.Select {
	display: inline-block;
}

.Select-control,
form input[type='text'],
form input[type='password'],
form input[type='number'],
form textarea {
	background-color: #fff;
	border-color: #d9d9d9 #ccc #b3b3b3;
	border-radius: 4px;
	border: 1px solid #ccc;
	color: #333;
	border-spacing: 0;
	border-collapse: separate;
	height: 36px;
	outline: none;
}

form input[type='text'],
form input[type='password'],
form input[type='number'] {
	padding-left: 10px;
	padding-right: 10px;
}

form input[type='checkbox'] {
	margin-top: 0.6em;
}

form input[type='file'] {
	margin-top: 0.2em;
}

form textarea {
	width: 500px;
	height: 90px;
	padding: 0.5em;
	vertical-align: middle;
}

.btn-inline {
	margin-left: 0.5em;
}

.rdt {
	color: $black;
	font-size: 1rem;
	.rdtPicker {
		width: 100%;
	}
	.rdtCounter {
		width: 60px;
	}
}
